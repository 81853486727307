"use client";

import { usePathname } from "next/navigation";
import { useEffect } from "react";
const ROUTE_CHANGE_LISTENERS = new Set<() => void>();
export const onNextRouteChange = (callback: () => void) => {
  ROUTE_CHANGE_LISTENERS.add(callback);
};
export const RouterChangeListener = () => {
  const pathname = usePathname();
  useEffect(() => {
    if (ROUTE_CHANGE_LISTENERS.size > 0) {
      for (const callback of ROUTE_CHANGE_LISTENERS) {
        callback();
      }
      ROUTE_CHANGE_LISTENERS.clear();
    }
  }, [pathname]);
  return null;
};