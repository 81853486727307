import { Api } from "api-def";
import type { AnalyticsEvent, CreateAnalyticsEventOptions } from "shared/AnalyticsEvent";
import type { BoardAnalytics, BoardSummary, BoardWithCards } from "shared/Board";
import type { Card, ImageCard } from "shared/Card";
import type { PlanTypeId } from "shared/Plans";
import type { PaymentReturnType } from "shared/SharedConstants";
import type { AuthState, PaymentSessionStatus, PriceInfo, TenorGif } from "shared/SharedTypes";
import type { ShortLink } from "shared/ShortLink";
import type { User } from "shared/User";
import type { View } from "shared/View";
import { isTokenExpiredError } from "src/lib/ClientUtils";
import { BACKEND_URL } from "src/lib/FrontendConstants";
import type { BoardUpdate } from "src/lib/state/BoardEditContext";

let refreshAuthTokenPromise: Promise<any> | null = null;

export const API = new Api({
  baseUrl: BACKEND_URL,
  name: "Backend",
  config: {
    credentials: "include",
  },
  middleware: [
    {
      beforeSend: async (context) => {
        try {
          if (refreshAuthTokenPromise) {
            await refreshAuthTokenPromise;
          }
        } catch {
          // Ignore -- handled in original request
        }

        if (typeof window === "undefined") {
          const cookies = await require("next/headers").cookies();
          context.updateHeaders({
            cookie: cookies.toString(),
          });
          const { getServerTraceData } = require("src/lib/ServerTraceData");
          context.updateHeaders(getServerTraceData());
        }
      },

      error: async (context) => {
        /*if (typeof window === "undefined") {
          console.error(
            "Server side request failed",
            context.path,
            context.response?.data,
            context.error?.stack,
          );
          if (isRequestError(context.error)) {
            if (context.error.response?.data?.code === "misc/internal-error") {
              Sentry.captureException(context.error);
            }
          }
        }*/
        if (isTokenExpiredError(context.error) && typeof window !== "undefined") {
          await (refreshAuthTokenPromise ||
            (refreshAuthTokenPromise = postRefreshAuthToken.submit({
              body: {},
            })));
          refreshAuthTokenPromise?.finally(() => {
            refreshAuthTokenPromise = null;
          });
          return {
            type: "retry",
          };
        }
      },
      unrecoverableError: () => {},
    },
  ],
});

export const patchUpdateBoard = API.endpoint()
  .bodyOf<{ data: BoardUpdate }>()
  .responseOf<{ board: BoardWithCards }>()
  .paramsOf<"boardIdOrSlug">()
  .build({
    id: "updateBoard",
    method: "patch",
    path: "/boards/:boardIdOrSlug",
  });

export const fetchBoard = API.endpoint()
  .responseOf<{ board: BoardWithCards }>()
  .paramsOf<"boardIdOrSlug">()
  .build({
    id: "getBoard",
    method: "get",
    path: "/boards/:boardIdOrSlug",
  });

export type CreateCardOptions<C extends Card = Card> = {
  type: C["type"];
  data: C["data"];
  layouts?: C["layouts"];
};

export const postAddCard = API.endpoint()
  .bodyOf<{ data: CreateCardOptions }>()
  .responseOf<{ card: Card }>()
  .paramsOf<"boardIdOrSlug">()
  .build({
    id: "addCard",
    method: "post",
    path: "/boards/:boardIdOrSlug/cards",
  });

export interface SignInOptions {
  idToken: string;
  refreshToken: string;
  profilePictureUrl?: string;
}

export const postAuthSignIn = API.endpoint()
  .bodyOf<SignInOptions>()
  .responseOf<{ authState: AuthState }>()
  .build({
    id: "auth",
    method: "post",
    path: "/auth/sign-in",
  });

export const postAuthSignOut = API.endpoint().build({
  id: "auth",
  method: "post",
  path: "/auth/sign-out",
});

export const deleteCard = API.endpoint()
  .responseOf<{ success: boolean }>()
  .paramsOf<"boardIdOrSlug" | "cardId">()
  .build({
    id: "deleteCard",
    method: "delete",
    path: "/boards/:boardIdOrSlug/cards/:cardId",
  });

export const fetchCurrentBoard = API.endpoint().responseOf<{ board: BoardWithCards }>().build({
  id: "getSessionBoard",
  method: "get",
  path: "/current-board",
});

export const getAuthMe = API.endpoint().responseOf<{ authState: AuthState }>().build({
  id: "getAuthMe",
  method: "get",
  path: "/auth/me",

  config: {},
});

export const postGenerateView = API.endpoint()
  .bodyOf<{
    type: string;
    data: any;
  }>()
  .responseOf<{ view: View }>()
  .build({
    id: "generateView",
    path: "/generate-view",
    method: "post",
  });

export const postPublishBoard = API.endpoint()
  .paramsOf<"boardId">()
  .bodyOf<{ slug: string }>()
  .responseOf<{ board: BoardWithCards }>()
  .build({
    id: "publishBoard",
    method: "post",
    path: "/boards/:boardId/publish",
  });

export const postCreatePlanPaymentSession = API.endpoint()
  .bodyOf<{ planTypeId: PlanTypeId; returnType: PaymentReturnType }>()
  .responseOf<{ sessionId: string; clientSecret: string }>()
  .build({
    id: "createPlanPaymentSession",
    method: "post",
    path: "/user/plan/payment-sessions",
  });

export const fetchPaymentSessionStatus = API.endpoint()
  .paramsOf<"sessionId">()
  .responseOf<PaymentSessionStatus>()
  .build({
    id: "getPaymentSessionStatus",
    method: "get",
    path: "/payment/sessions/:sessionId/status",
  });

export const fetchUserBoardSummaries = API.endpoint()
  .responseOf<{ results: BoardSummary[] }>()
  .build({
    id: "getBoardSummaries",
    method: "get",
    path: "/user/board-summaries",
  });

export const postStartTrial = API.endpoint()
  .bodyOf<{
    draftSlug?: string;
  }>()
  .responseOf<{ success: true }>()
  .build({
    id: "startTrial",
    method: "post",
    path: "/start-trial",
  });

export const deleteBoard = API.endpoint()
  .paramsOf<"boardIdOrSlug">()
  .responseOf<{ success: boolean }>()
  .build({
    id: "deleteBoard",
    method: "delete",
    path: "/boards/:boardIdOrSlug",
  });

export const postCreateBoard = API.endpoint().responseOf<{ data: BoardWithCards }>().build({
  id: "createBoard",
  method: "post",
  path: "/boards",
});

export const getCheckSlug = API.endpoint()
  .queryOf<{ slug: string; checkMaxBoards?: boolean }>()
  .responseOf<{ success: true }>()
  .build({
    id: "checkSlug",
    method: "get",
    path: "/board/check-slug",
  });

export const patchUpdateCard = API.endpoint()
  .bodyOf<{ data: Partial<Card> }>()
  .paramsOf<"cardId" | "boardIdOrSlug">()
  .responseOf<{ card: Card }>()
  .build({
    id: "updateCard",
    method: "patch",
    path: "/boards/:boardIdOrSlug/cards/:cardId",
  });

export const postUserPlanDeactivate = API.endpoint().responseOf<{ canceledAt: number }>().build({
  id: "deactivatePlan",
  method: "post",
  path: "/user/plan/deactivate",
});

export const postUserPlanReactivate = API.endpoint().responseOf<{ success: true }>().build({
  id: "reactivatePlan",
  method: "post",
  path: "/user/plan/reactivate",
});

export const postUserPlanChange = API.endpoint()
  .bodyOf<{ planTypeId: string }>()
  .responseOf<{ user: User }>()
  .build({
    id: "changePlan",
    method: "post",
    path: "/user/plan/change",
  });

export const deleteUser = API.endpoint().responseOf<{ success: true }>().build({
  id: "deleteUser",
  method: "delete",
  path: "/user",
});

export const postRefreshAuthToken = API.endpoint()
  .responseOf<{ authState: AuthState }>()
  .bodyOf<{
    refreshToken?: string;
  }>()
  .build({
    id: "refreshAuthToken",
    method: "post",
    path: "/refresh-token",
  });

export const getShortLink = API.endpoint()
  .responseOf<{ result: ShortLink }>()
  .paramsOf<"linkId">()
  .build({
    id: "getShortLink",
    method: "get",
    path: "/short-links/:linkId",
  });

export const postCreateAnalyticsEvent = API.endpoint()
  .bodyOf<
    Omit<CreateAnalyticsEventOptions, "visitorData"> & {
      visitorData?: CreateAnalyticsEventOptions["visitorData"];
    }
  >()
  .responseOf<{ event: AnalyticsEvent }>()
  .build({
    id: "createAnalyticsEvent",
    method: "post",
    path: "/analytics-events",
  });

export const getBoardAnalytics = API.endpoint()
  .paramsOf<"boardIdOrSlug">()
  .responseOf<BoardAnalytics>()
  .build({
    id: "getBoardAnalyticsViewData",
    method: "get",
    path: "/boards/:boardIdOrSlug/analytics",
  });

export const postImageUpload = API.endpoint()
  .bodyOf<{
    image: string;
  }>()
  .responseOf<ImageCard["data"]>()
  .build({
    id: "postImageUpload",
    method: "post",
    path: "/images",
  });

export const fetchPlanPricing = API.endpoint()
  .responseOf<{ prices: Record<PlanTypeId, PriceInfo> }>()
  .queryOf<{ countryCode?: string }>()
  .build({
    id: "getPlanPricing",
    method: "get",
    path: "/plan-pricing",
  });

export const fetchGifs = API.endpoint()
  .responseOf<{ results: TenorGif[] }>()
  .queryOf<{ searchTerm: string }>()
  .build({
    id: "getGifs",
    method: "get",
    path: "/gifs",
  });

if (typeof window !== "undefined") {
  // @ts-ignore
  window.__api = API;
}
