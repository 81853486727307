"use client";

import AppLayout from "src/components/app/AppLayout/AppLayout";
import ErrorPage, { type ErrorPageProps } from "src/components/app/ErrorPage/ErrorPage";
export interface GlobalErrorPageProps extends ErrorPageProps {}
const GlobalErrorPage = (props: GlobalErrorPageProps) => {
  const {
    ...otherProps
  } = props;
  return <AppLayout data-sentry-element="AppLayout" data-sentry-component="GlobalErrorPage" data-sentry-source-file="global-error.tsx">
      <ErrorPage {...otherProps} data-sentry-element="ErrorPage" data-sentry-source-file="global-error.tsx" />
    </AppLayout>;
};
export default GlobalErrorPage;