import { toTitleCase } from "src/lib/ClientUtils";
import { APP_VERSION } from "src/lib/FrontendConstants";
export type BuildWatermarkProps = {};
const BuildWatermark = (props: BuildWatermarkProps) => {
  return <ul className={"fixed top-2 right-2 text-right text-sm opacity-30  z-[1000] pointer-events-none"} style={{
    textShadow: "1px 1px 1px white"
  }} data-sentry-component="BuildWatermark" data-sentry-source-file="BuildWatermark.tsx">
      {process.env.NEXT_PUBLIC_ENVIRONMENT ? <li>
          <b>
            {toTitleCase(process.env.NEXT_PUBLIC_ENVIRONMENT)} | {APP_VERSION}
          </b>
        </li> : null}
      <li className={"flex gap-1"}>
        {process.env.NEXT_PUBLIC_BUILD_TIME ? <span>{new Date(process.env.NEXT_PUBLIC_BUILD_TIME).toLocaleString()}</span> : null}
      </li>
    </ul>;
};
export default BuildWatermark;